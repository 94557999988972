@charset "UTF-8";
@import "ap_preaset";

/****************
***** footer ****
****************/

footer {
  a {
    user-select: none;
  }
  .iconOutLink {
    width: 16px;
    height: 16px;
    background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_out_link.svg") no-repeat center center;
    background-size: cover;
  }
  .iconArrowRight {
    width: 16px;
    height: 16px;
    background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_right.svg") no-repeat center center;
    background-size: cover;
  }

  width: 100%;
  background-color: #f9f9fb;
  -webkit-font-smoothing: antialiased;

  .ul-hidden {
    display: none;
  }

  .footer {
    padding: 40px 0 80px;

    @include rwd("md") {
      padding: 16px 0 32px;
    }

    .footer-main {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;

      @include rwd("xl") {
        justify-content: space-between;
        gap: 0;
      }
      @include rwd("md") {
        &.comPd {
          padding: 0;
        }
      }
    }

    .footer-divider {
      height: 1px;
      background: #edf0f5;
      margin: 24px 0;
    }

    .footer-bottom {
      display: flex;
      gap: 32px;
      justify-content: stretch;

      .image-wrapper {
        &.show-md {
          display: none;
        }
      }

      @include rwd("lg") {
        flex-direction: column;
      }

      @include rwd("md") {
        gap: 8px;

        &.comPd {
          padding: 0;
        }
        .image-wrapper {
          display: none;
          &.show-md {
            display: flex;
          }
          width: auto;
          height: 56px;
          padding: 0 16px;
          align-items: center;
          position: relative;
          background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_down.svg) no-repeat right 16px center;
          background-size: 16px;
          cursor: pointer;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_down.svg) no-repeat right 16px center #F4F6F8;
              background-size: 16px;
            }
          }
        }
        .footer-info {
          display: none;
          padding: 0 16px 16px 16px;
          gap: 16px;
          ul {
            line-height: 22px;
            li {
              gap: 0 8px;
            }
          }
        }

        &.open {
          .image-wrapper {
            background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_up.svg) no-repeat right 16px center;
            background-size: 16px;
          }
          .footer-info {
            display: block;
          }
        }
      }
    }

    .box {
      width: 200px;
      &.call-center {
        width: 280px;
        margin-left: auto;

        @include rwd("xl") {
          margin-left: 0;
        }
        @include rwd("lg") {
          width: 100%;
          margin-top: 40px;
          margin-left: 0;
        }
        @include rwd("md") {
          margin-top: 16px;
          .items-center {
            padding: 16px 0;
            margin-bottom: 8px;
          }
        }
      }

      h3 {
        color: var(--Text-GreyScale-Grey100, #171a22);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 157.143%;
        margin-bottom: 16px;

        @include rwd("md") {
          font-size: 16px;
        }
      }

      li {
        color: var(--Text-GreyScale-Grey90, #333d4b);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 157.143%;
        padding: 6px 0;

        span.label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
        }

        a {
          &:hover {
            text-decoration: underline;
            color: #171a22;
          }
        }
      }

      .moreCall {
        color: var(--Text-GreyScale-Grey80, #6d7788);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
          color: #171a22;
        }
      }

      .phone {
        color: var(--Text-GreyScale-Grey100, #171a22);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 153.846% */
      }

      .ask-email {
        margin-top: 16px;
        display: flex;
        width: 100%;
        height: 44px;
        padding: var(--Radius-Radius-Medium, 10px) var(--Radius-Radius-XLarge, 16px);
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--Stroke-GreyScale-Grey50, #d4dae5);
        background: var(--Frame-GreyScale-Color, #fff);

        color: var(--Text-GreyScale-Grey100, #171a22);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        &:hover {
          border: 1px solid var(--Stroke-GreyScale-Grey50, #d4dae5);
          background: var(--Frame-GreyScale-Grey20, #f4f6f8);
        }
      }

      @include rwd("md") {
        width: 100%;
        cursor: pointer;

        h3 {
          cursor: pointer;
          display: block;
          padding: 16px;
          position: relative;
          margin: 0;
          background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_down.svg) no-repeat right 16px center;
          background-size: 16px;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_down.svg) no-repeat right 16px center #F4F6F8;
              background-size: 16px;
            }
          }
        }

        ul {
          display: none;
        }
        &.call-center {
          padding: 0 16px;
          h3 {
            padding: 16px 0;
            display: flex;
            background: none;
          }
        }

        &.open {
          padding-bottom: 16px;
          h3 {
            background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_arrow_up.svg) no-repeat right 16px center;
            background-size: 16px;
          }
          &.call-center {
            padding-bottom: 0;
            h3 {
              background: none;
            }
          }
          ul {
            display: block;
            li {
              padding: 10px 16px;
              &:hover {
                background: #f4f6f8;
              }
              a {display: block}
            }
          }
        }
      }
    }
  }

  //  SNS 아이콘
  .snsWrapper {
    .title {
      margin: 12px 0;
      @include rwd("md") {
        margin: 16px 0;
      }
      color: var(--Text-GreyScale-Grey80, #6d7788);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */

      &:hover {
        text-decoration: underline;
        color: #171a22;
      }
    }
    .snsBtn-group {
      display: flex;
      gap: 24px;
    }
    .snsBtn {
      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        img {
          filter: brightness(0.5);
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    width: toRem(224);
    justify-content: center;
    align-items: center;
    margin-top: toRem(16);
    padding: toRem(6) 0;
    background-color: $ap-line-gray;
    border-radius: toRem(8);

    img {
      width: toRem(32);
      height: toRem(32);
      opacity: 0.3;
      margin-left: toRem(-8);
    }

    span {
      color: $ap-text-dark;
      font-size: toRem(14);
    }
  }

  .timeWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .divider {
      width: 1px;
      height: var(--Radius-Radius-Large, 12px);
      background: var(--Frame-GreyScale-Grey40, #e3e7ee);
    }

    color: var(--Text-GreyScale-Grey90, #333d4b);
    .label,
    .time {
      color: var(--Text-GreyScale-Grey90, #333d4b);
    }

    /* Body md/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }

  .left-contents {
    display: flex;
    gap: toRem(36);
  }

  .image-wrapper {
    padding-top: toRem(4);

    img {
      width: 100px;
      filter: brightness(0.7);
    }
  }

  .footer-info {
    flex: 1;
    ul {
      width: 100%;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 8px;
      }
      .divider {
        width: 1px;
        height: var(--Radius-Radius-Large, 12px);
        background: var(--Frame-GreyScale-Grey40, #e3e7ee);
      }
    }

    ul li span {
      color: var(--Text-GreyScale-Grey90, #333d4b);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }

  .copy-right {
    width: 280px;
    @include rwd("md") {
      padding: 0 16px;
      width: auto;
    }
    p {
      color: var(--Text-GreyScale-Grey90, #333d4b);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }

  .strong {
    color: #222;
    font-size: toRem(48);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .psnInfo {
    font-weight: 600;
  }
}

/** modalBottomSheet - 예약센터 팝업 */
#modalBottomSheet{
  .bookingCenterContents > li{
    padding: toRem(16);
    background-color: #F9F9FB;
    border-radius: toRem(20);
    margin-bottom: toRem(10);
  }

  .bookingCenterContents > li:last-child{
    margin-bottom: 0;
  }
  
  .bookingCenterContents .centerTitle{
    font-size: toRem(16);
    line-height: toRem(24);
  }
  
  .bookingCenterContents .contactNumber{
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(24);
  }
  
  .bookingCenterContents .languageBadge{
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    line-height: toRem(18);
    padding: 0 toRem(8);
    background: #E6EAF0;
    border-radius: toRem(10000);
    margin-bottom: toRem(12);
  }

  .bookingCenterContents .operationHours{
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #9AA2B1;
  }

  .bookingCenterContents .contactUSKoreanEnglish > li:first-child{
    margin-bottom: toRem(12);
    padding-bottom: toRem(12);
    border-bottom: toRem(1) solid #EDF0F5;
  }

  @media (min-width: 600px) {
    .bookingCenterContents .iconContact{
      display: none;
    }
    .bookingCenterContents .contactUSKoreanEnglish > li:first-child{
      margin-bottom: toRem(6);
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}